html,
body {
  background-color: #fff;
  /* background-color: #f3f6f9; */
  padding: 0;
  margin: 0;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.CookieConsent {
  max-width: 400px;
  border-radius: 18px;
  background: white !important;
  left: auto !important;
  right: 24px !important;
  bottom: 24px !important;
  color: #6e6e73 !important;
  box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
}

@media only screen and (max-width: 883px) {
  .CookieConsent {
    max-width: 90%;
    right: 24px !important;
    left: 24px !important;
    margin: auto !important;
  }
}

/*.grecaptcha-badge {*/
/*  z-index: 999 !important;*/
/*}*/

.CookieInner {
  margin: 16px 16px 0px 16px !important;
}

.link {
  color: rgba(36, 138, 255, 1);
}

.link:hover {
  text-decoration: underline;
}
