.tippy-box[data-theme~="light-custom"] {
  background-color: #090d14;
  color: white;
  border-radius: 10px;
  padding: 8px;
  border: 1.5px solid #2a3136;
  text-align: center;
  font-weight: 600;
  box-shadow: 0 5px 10px rgb(0 0 0 / 34%);
  max-width: 300px !important;
}

/* The fill */
.tippy-box[data-theme~="light-custom"] > .tippy-svg-arrow > svg:last-child {
  fill: #090d14;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes shine {
  20%,
  100% {
    transform: translateX(125%) skew(20deg);
  }
}

.shining-badge {
  border: 1px solid hsla(0, 0%, 100%, 0.15);
  background: rgba(255, 255, 255, 0.02);
  border-radius: 999px;
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: 999px;
  font-weight: 400;
  margin: 0;
  color: hsla(0, 0%, 100%, 0.75);
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: background 0.2s ease;
  cursor: pointer;
}

.shining-badge::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 200%;
  background: hsla(0, 0%, 100%, 0.075);
  transform: translateX(-100%) skew(20deg);
  animation: shine 8s infinite;
}

.shining-badge:hover {
  background: rgba(255, 255, 255, 0.15);
}

.fade-in1 {
  animation: 1200ms ease 0ms 1 normal backwards running fadeIn;
}

.fade-in2 {
  animation: 1000ms ease 0s 1 normal backwards running fadeIn;
}

.fade-in3 {
  animation: 1200ms ease 400ms 1 normal backwards running fadeIn;
}

.fade-in4 {
  animation: 1200ms ease 800ms 1 normal backwards running fadeIn;
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.shimmer {
  position: relative;
  background: #3a3a3a;
  background-image: linear-gradient(
    to right,
    #3a3a3a 0%,
    #3f3f3f 10%,
    #4a4a4a 20%,
    #3f3f3f 30%,
    #3a3a3a 50%,
    #3a3a3a 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 200px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: ease-in-out;
}

.w-full {
  width: 100%;
}

.w-1 {
  width: 10%;
}

.w-2 {
  width: 20%;
}

.w-3 {
  width: 30%;
}

.w-4 {
  width: 40%;
}

.w-5 {
  width: 50%;
}

.w-6 {
  width: 60%;
}

.w-7 {
  width: 70%;
}

.w-8 {
  width: 80%;
}

.w-9 {
  width: 90%;
}

.h-1 {
  height: 1rem;
}

.h-2 {
  height: 2rem;
}

.h-3 {
  height: 3rem;
}

.h-4 {
  height: 4rem;
}

.h-5 {
  height: 5rem;
}

.square {
  height: 200px;
  width: 200px;
}
